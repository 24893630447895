/** @format */

import React, { useContext, useEffect, useRef } from "react";
import "./ReceptionistDashboard.css";
import ropa_logo from "../../../assets/mob-logo.svg";
import receptionist2_pic from "../../../assets/receptionist_2.jpg";
import { Link, useNavigate } from "react-router-dom";
import { Store } from "../../../Store";
import { NavLink } from "react-router-dom";
import top_icon from "../../../assets/top_arrow.svg";

const ReceptionistDashboard = ({
  setReceptionProfileShow,
  receptionProfileShow,
}) => {
  const navigate = useNavigate();

  const reception = useRef(null);

  const closeReception = (e) => {
    if (e.target === reception.current) {
      setReceptionProfileShow(false);
    }
  };

  // const handleResize = () => {
  //   if (window.innerWidth > 650) {
  //     setReceptionProfileShow(true);
  //   } else {
  //     setReceptionProfileShow(false);
  //   }
  // };

  const {
    state: { userInfo },
    dispatch,
  } = useContext(Store);

  // Redirect if userInfo or userInfo._id is undefined
  useEffect(() => {
    if (!userInfo || !userInfo._id) {
      navigate("/SignIn");
    }
  }, [userInfo, navigate]);

  // const staffId = userInfo._id;

  // Avoid accessing userInfo._id if userInfo is undefined
  // const { data: staff } = useGetStaffDetailQuery(staffId);

  // console.log(staff);

  const signoutHandler = () => {
    dispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    window.location.href = "/#/SignIn";
  };
  return (
    <div
      className={`ReceptionistDashboard ${receptionProfileShow ? " " : "hide"}`}
    >
      <div className="ReceptionistDashboard-arrow-up">
        <img src={top_icon} alt="" ref={reception} onClick={closeReception} />
      </div>
      <div className="ReceptionistDashboard-upper-sec">
        <div className="ReceptionistDashboard-logo">
          <Link to="/">
            {" "}
            <img src={ropa_logo} alt="" />
          </Link>
        </div>
        <div className="ReceptionistDashboard-profile">
          <div className="rdb-profile">
            <img src={receptionist2_pic} alt="" />
          </div>
          <h2>Receptionist II</h2>
          <p>{userInfo ? userInfo.fullName : ""}</p>
        </div>
      </div>
      <div className="ReceptionistDashboard-lower-sec">
        <ul>
          <NavLink
            to="/StaffDashboard"
            className={({ isActive }) => {
              return isActive ? "hover-rdb-btn" : "";
            }}
          >
            <li>Home</li>
          </NavLink>

          <NavLink
            to="/ReceptionAccount"
            className={({ isActive }) => {
              return isActive ? "hover-rdb-btn" : "";
            }}
          >
            <li>Payments</li>
          </NavLink>

          <NavLink
            to="/History"
            className={({ isActive }) => {
              return isActive ? "hover-rdb-btn" : "";
            }}
          >
            <li>History</li>
          </NavLink>
          <li>
            <button
              className="logout-btn ReceptionistDashboard-login"
              onClick={signoutHandler}
            >
              Signout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReceptionistDashboard;
