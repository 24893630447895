import React, { useContext, useRef } from "react";
import "./BartenderProfile.css";
import ropa_logo from "../../../assets/mob-logo.svg";
import admin_pic from "../../../assets/receptionist_1.jpg";
import top_icon from "../../../assets/top_arrow.svg";
import { NavLink, Link } from "react-router-dom";
import { Store } from "../../../Store";

const BartenderProfile = ({ barProfileShow, setBarProfileShow }) => {
  const barProfile = useRef(null);

  const { dispatch } = useContext(Store);

  // Signout handler function
  const signoutHandler = () => {
    dispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    window.location.href = "/#/SignIn";
  };

  return (
    <div className={`BartenderProfile  ${barProfileShow ? "" : "hide"}`}>
      <div className="BartenderProfile-arrow-up">
        <img
          src={top_icon}
          alt=""
          ref={barProfile}
          onClick={() => setBarProfileShow(false)}
        />
      </div>
      <div className="BartenderProfile-upper-sec">
        <div className="BartenderProfile-logo">
          <Link to="/">
            <img
              src={ropa_logo}
              alt=""
              ref={barProfile}
              // onClick={closeProfile}
            />
          </Link>
        </div>
        <div className="BartenderProfile-profile">
          <div className="BartenderProfile-profile">
            <img src={admin_pic} alt="" />
          </div>
          <h2>Prince Ayo</h2>
          <p>Good afternoon</p>
        </div>
      </div>
      <div className="BartenderProfile-lower-sec">
        <ul>
          {/*DashboardRoom*/}
          <NavLink
            to="/StaffDashboard"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Home</li>
          </NavLink>
          <NavLink
            to="/BartenderAccount"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Payment</li>
          </NavLink>

          {/* <NavLink> */}
          <button
            className="logout-btn ReceptionistDashboard-login"
            onClick={signoutHandler}
          >
            Signout
          </button>
          {/* </NavLink> */}
        </ul>
      </div>
    </div>
  );
};

export default BartenderProfile;
