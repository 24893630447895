import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Store } from "../../../Store";
import ropa_logo from "../../../assets/mob-logo.svg";
import admin_pic from "../../../assets/receptionist_1.jpg";
import top_icon from "../../../assets/top_arrow.svg";
import {
  useGetStaffDetailQuery,
  useUpdateStaffMutation,
} from "../../../hooks/staff";
import { convertToBase64 } from "../../../utils/convertImg";
import "./AdminProfile.css";

const AdminProfile = ({ adminProfileShow, setAdminProfileShow }) => {
  const navigate = useNavigate();

  const {
    state: { userInfo },
    dispatch,
  } = useContext(Store);

  const { data: staff } = useGetStaffDetailQuery(userInfo?._id);
  const { mutateAsync: updateProfile, refetch } = useUpdateStaffMutation();

  useEffect(() => {
    if (!userInfo || !userInfo._id) {
      navigate("/SignIn");
    }
  }, [userInfo, navigate]);

  // Signout handler function
  const signoutHandler = () => {
    dispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    window.location.href = "/#/SignIn";
  };

  // Greeting function
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good morning";
    } else if (currentHour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  // Initialize the photo state based on `staff` value
  const [photo, setProfilePhoto] = useState(admin_pic);
  const [isPhotoUpdated, setIsPhotoUpdated] = useState(false);

  useEffect(() => {
    if (staff?.photo) {
      setProfilePhoto(staff.photo);
    }
  }, [staff]);

  const uploadPhoto = async (e) => {
    const target = e.target;
    const { files } = target;
    const file = files[0];
    if (file) {
      try {
        const base64 = await convertToBase64(file);

        setProfilePhoto(base64);
        setIsPhotoUpdated(true);
      } catch (error) {
        console.error("Error converting image:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await updateProfile({ photo });
      toast.success("Profile updated");
      console.log(data);
      refetch();
      setIsPhotoUpdated(false);
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  return (
    <div className={`AdminProfile  ${adminProfileShow ? "" : "hide"}`}>
      <div className="AdminProfile-arrow-up">
        <img src={top_icon} alt="" onClick={() => setAdminProfileShow(false)} />
      </div>
      <div className="AdminProfile-upper-sec">
        <div className="AdminProfile-logo">
          <Link to="/">
            <img src={ropa_logo} alt="" />
          </Link>
        </div>
        <div className="AdminProfile-profile">
          <label htmlFor="upload-photo">
            <img src={photo} alt="Staff Profile" />
          </label>
          <form onSubmit={handleSubmit}>
            <input
              type="file"
              onChange={uploadPhoto}
              id="upload-photo"
              style={{ display: "none" }}
              accept="image/*"
            />
            {isPhotoUpdated && (
              <button
                type="submit"
                className="update-btn"
                style={{ color: "green", padding: "8px" }}
              >
                Update
              </button>
            )}
          </form>
          <h2>{userInfo && userInfo.fullName}</h2>
          <br />
          <p>{getGreeting()}</p>
        </div>
      </div>
      <div className="AdminProfile-lower-sec">
        <ul>
          <NavLink
            to="/AdminDashboard"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Home</li>
          </NavLink>
          <NavLink
            to="/AdminAccounts"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Account</li>
          </NavLink>
          <NavLink
            to="/StaffList"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Staff</li>
          </NavLink>

          <li>
            <button
              className="logout-btn ReceptionistDashboard-login"
              onClick={signoutHandler}
            >
              Signout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminProfile;
