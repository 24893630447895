import React, { useContext, useEffect, useRef } from "react";
import "./CashierProfile.css";
import ropa_logo from "../../../assets/mob-logo.svg";
import admin_pic from "../../../assets/receptionist_1.jpg";
import top_icon from "../../../assets/top_arrow.svg";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Store } from "../../../Store";

const CashierProfile = ({ cashProfileShow, setCashProfileShow }) => {
  const navigate = useNavigate();

  const {
    state: { userInfo },
    dispatch,
  } = useContext(Store);

  // Redirect if userInfo or userInfo._id is undefined
  useEffect(() => {
    if (!userInfo || !userInfo._id) {
      navigate("/SignIn");
    }
  }, [userInfo, navigate]);

  // Signout handler function
  const signoutHandler = () => {
    dispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    window.location.href = "/#/SignIn";
  };

  // Greeting function
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good morning";
    } else if (currentHour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  const cashProfile = useRef(null);
  return (
    <div className={`CashierProfile  ${cashProfileShow ? "" : "hide"}`}>
      <div className="CashierProfile-arrow-up">
        <img
          src={top_icon}
          alt=""
          ref={cashProfile}
          onClick={() => setCashProfileShow(false)}
        />
      </div>
      <div className="CashierProfile-upper-sec">
        <div className="CashierProfile-logo">
          <Link to="/">
            <img src={ropa_logo} alt="" ref={cashProfile} />
          </Link>
        </div>
        <div className="CashierProfile-profile">
          <div className="gdb-profile">
            <img src={admin_pic} alt="" />
          </div>
          <h2>{userInfo && userInfo.fullName}</h2>
          <p>{getGreeting()}</p>
        </div>
      </div>
      <div className="CashierProfile-lower-sec">
        <ul>
          <NavLink
            to="/StaffDashboard"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Home</li>
          </NavLink>
          <NavLink
            to="/CashierAccount"
            className={({ isActive }) => {
              return isActive ? "hover-gdb-btn" : "";
            }}
          >
            <li>Account</li>
          </NavLink>
          <li>
            <button
              className="logout-btn ReceptionistDashboard-login"
              onClick={signoutHandler}
            >
              Signout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CashierProfile;
